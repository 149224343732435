import './BankSetting.scss';
import {i18n} from '../../locale/i18n';
import {AlertConfig} from '../parts/Alert';
import {AlertState} from '../../reducers/alert';

import {connect} from 'react-redux';
import {useState, useEffect} from 'react';
import {GlobalState} from '../../reducers/index';
import {FranchiseStoresState} from '../../reducers/franchiseStores';
import {UserInfoState} from '../../reducers/userInfo';
import SideBar, {selectedPages} from '../../components/parts/SideBar';
import SubmitButton from '../../components/parts/SubmitButton';
import CancelButton from '../../components/parts/CancelButton';
import Header from '../../components/parts/Headers/UpperLimitAmount';
import {Dispatch} from 'redux';
import putUser from '../../actions/putUser';

import {FinancialInstitutionBranchState} from '../../reducers/financialInstitutionBranches';
import {FinancialInstitutionState} from '../../reducers/financialInstitution';

import fetchFinancialInstitution from '../../actions/fetchFinancialInstitutions';
import fetchFinancialInstitutionBranches from '../../actions/fetchFinancialInstitutionBranches';
import removeFinancialInstitutionBranches from '../../actions/removeFinancialInstitutionBranches';
import {useNavigate} from 'react-router-dom';
import convertAccountName from '../../util/convertAccountName';
import RestrictedPage from '../auth/RestrictedPage';

type StatesFromReducer = UserInfoState &
  AlertState &
  FranchiseStoresState &
  FinancialInstitutionState &
  FinancialInstitutionBranchState;

interface DispatchProps {
  submitBankInfo: typeof putUser;
  loadFinancialInstitution: typeof fetchFinancialInstitution;
  loadFinancialInstitutionBranch: typeof fetchFinancialInstitutionBranches;
  clearFinancialInstitutionBranches: typeof removeFinancialInstitutionBranches;
}

const InnerComponent = ({
  user,
  open,
  selected,
  submitBankInfo,
  financialInstitutions,
  financialInstitutionBranches,
  loadFinancialInstitution,
  loadFinancialInstitutionBranch,
  clearFinancialInstitutionBranches,
}: StatesFromReducer & DispatchProps) => {
  const [
    financialInstitutionSelectorOpened,
    setFinancialInstitutionSelectorOpened,
  ] = useState(false);
  const [branchNameSelectorOpened, setBranchNameSelectorOpened] =
    useState(false);
  const [financialInstitutionName, setFinancialInstitutionName] = useState(
    user.financialInstitutionName || ''
  );
  const [branchName, setBranchName] = useState(user.branchName || '');
  const [accountType, setAccountType] = useState(user.accountType || 1);
  const [accountNumber, setAccountNumber] = useState(
    user.unmaskedAccountNumber
  );
  const [accountNumberError, setAccountNumberError] = useState<string | null>(
    null
  );
  const [accountName, setAccountName] = useState(user.accountName);
  const [accountNameError, setAccountNameError] = useState<string | null>(null);

  const employeeID = selected ? selected.employeeID : 0;

  const [financialInstitutionCode, setFinancialInstitutionCode] = useState<
    string | null
  >(user.financialInstitutionCode || null);

  const [branchCode, setBranchCode] = useState<string | null>(
    user.branchCode || null
  );
  const [canselButtonPushed, setCanselButtonPushed] = useState(false);

  useEffect(() => {
    setFinancialInstitutionName(user.financialInstitutionName || '');
    setBranchName(user.branchName || '');
    setAccountType(user.accountType || 1);
    setAccountNumber(user.unmaskedAccountNumber);
    setAccountName(user.accountName);
  }, [user, employeeID]);

  useEffect(() => {
    if (financialInstitutions.length === 1) {
      setFinancialInstitutionCode(
        financialInstitutions[0].financialInstitutionCode
      );
    }
  }, [financialInstitutions, employeeID, user.branchName]);

  useEffect(() => {
    if (financialInstitutionBranches.length === 1) {
      setBranchCode(financialInstitutionBranches[0].branchCode);
    }
  }, [financialInstitutionBranches]);

  const navigate = useNavigate();
  useEffect(() => {
    if (selected && !selected.allowEmployeeEditAccount) {
      navigate('/settings');
    }
  }, [selected, navigate]);

  useEffect(() => {
    if (open || canselButtonPushed) {
      navigate('/settings');
    }
  }, [open, canselButtonPushed, navigate]);

  const enableChange =
    !!selected &&
    !!financialInstitutionCode &&
    !!branchCode &&
    !!accountNumber &&
    !!accountName &&
    !accountNumberError &&
    !accountNameError;

  return (
    <RestrictedPage>
      <Header />
      <main id='bank-setting'>
        <link
          href='https://fonts.googleapis.com/css?family=Manrope'
          rel='stylesheet'
        />
        <section>
          <section>
            <section>
              <h2>登録口座の変更</h2>
              <p>
                通帳またはカードなどをご用意の上、お間違えのないようお気をつけください。
              </p>
              <ul>
                <li>
                  <div>
                    <h3>金融機関名</h3>
                    <p>※入力後、表示されるリストから選択してください。</p>
                  </div>
                  <input
                    type='text'
                    placeholder='CRIA銀行'
                    value={financialInstitutionName}
                    onChange={e => {
                      const inputFinancialInstitution = e.target.value;
                      setFinancialInstitutionName(inputFinancialInstitution);
                      if (inputFinancialInstitution.length >= 2) {
                        setFinancialInstitutionCode(null);
                        loadFinancialInstitution(
                          employeeID,
                          inputFinancialInstitution
                        );
                      } else {
                        setFinancialInstitutionCode(null);
                      }
                    }}
                    onBlur={e =>
                      setTimeout(() => {
                        setFinancialInstitutionSelectorOpened(false);
                      }, 300)
                    }
                    onClick={() => setFinancialInstitutionSelectorOpened(true)}
                  />
                  {financialInstitutionSelectorOpened ? (
                    <div
                      className={
                        financialInstitutions.length > 7
                          ? 'modal scroll'
                          : 'modal'
                      }
                    >
                      <ul>
                        {financialInstitutions.map(bank => (
                          <li
                            key={bank.financialInstitutionCode}
                            onClick={() => {
                              if (
                                bank.financialInstitutionName ===
                                financialInstitutionName
                              ) {
                                return;
                              }
                              setFinancialInstitutionName(
                                bank.financialInstitutionName
                              );
                              setFinancialInstitutionCode(
                                bank.financialInstitutionCode
                              );
                              clearFinancialInstitutionBranches();
                              setBranchName('');
                              setBranchCode(null);
                            }}
                          >
                            {bank.financialInstitutionName}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
                <li>
                  <div>
                    <h3>{i18n.t('common.branch')}</h3>
                    <p>※入力後、表示されるリストから選択してください。</p>
                  </div>
                  <input
                    type='text'
                    placeholder='CRIA第一支店'
                    value={branchName}
                    onChange={e => {
                      const inputBranchName = e.target.value;
                      if (
                        financialInstitutionCode &&
                        inputBranchName.length >= 2
                      ) {
                        setBranchCode(null);
                        loadFinancialInstitutionBranch(
                          employeeID,
                          financialInstitutionCode,
                          inputBranchName
                        );
                      } else {
                        setBranchCode(null);
                        clearFinancialInstitutionBranches();
                      }
                      setBranchName(inputBranchName);
                    }}
                    onBlur={() =>
                      setTimeout(() => {
                        setBranchNameSelectorOpened(false);
                      }, 300)
                    }
                    onClick={() => setBranchNameSelectorOpened(true)}
                  />
                  {branchNameSelectorOpened ? (
                    <div
                      className={
                        financialInstitutionBranches.length > 7
                          ? 'modal scroll'
                          : 'modal'
                      }
                    >
                      <ul>
                        {financialInstitutionBranches.map(branch => (
                          <li
                            key={branch.branchCode}
                            onClick={() => {
                              if (branch.branchName === branchName) {
                                return;
                              }
                              setBranchName(branch.branchName);
                              setBranchCode(branch.branchCode);
                            }}
                          >
                            {branch.branchName}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>

                <li>
                  <h3>{i18n.t('common.accountType')}</h3>
                  <div>
                    <input
                      id='accountType1'
                      type='radio'
                      name='accountType'
                      onClick={() => setAccountType(1)}
                      defaultChecked={accountType === 1}
                    />
                    <label htmlFor='accountType1'>
                      {i18n.t('common.savingsAccount')}
                    </label>
                    <input
                      id='accountType2'
                      type='radio'
                      name='accountType'
                      onClick={() => setAccountType(2)}
                      defaultChecked={accountType === 2}
                    />
                    <label htmlFor='accountType2'>
                      {i18n.t('common.checkingAccount')}
                    </label>
                  </div>
                </li>

                <li>
                  <h3>{i18n.t('common.accountNumber')}</h3>
                  {accountNumberError ? (
                    <p className='caution'>{accountNumberError}</p>
                  ) : (
                    <></>
                  )}
                  <input
                    type='text'
                    placeholder={i18n.t('common.accountNumber')}
                    value={accountNumber}
                    onChange={e => {
                      if (!e.target.value.match(/^\d{7}$/)) {
                        setAccountNumberError(
                          '口座番号は半角数字7文字で入力してください'
                        );
                      } else {
                        setAccountNumberError(null);
                      }
                      setAccountNumber(e.target.value);
                    }}
                  />
                </li>

                <li>
                  <h3>{i18n.t('userInfo.accountHolder')}</h3>
                  {accountNameError ? (
                    <p className='caution'>{accountNameError}</p>
                  ) : (
                    <></>
                  )}
                  <input
                    type='text'
                    placeholder={i18n.t('userInfo.accountHolder')}
                    value={accountName}
                    onChange={e => {
                      const inputString = e.target.value;
                      const converted = convertAccountName(e.target.value);
                      if (
                        !converted.match(/^[ｱ-ﾝﾞﾟ0-9\sa-zA-Z().\-/]+$/) ||
                        !converted.match(/^[ｱ-ﾝﾞﾟ0-9\sa-zA-Z().\-/]{1,30}$/)
                      ) {
                        setAccountNameError(
                          '口座名義は30文字以内のカタカナで入力してください'
                        );
                      } else {
                        setAccountNameError(null);
                      }
                      setAccountName(inputString);
                    }}
                  />
                </li>
              </ul>
              <div className='buttons'>
                <SubmitButton
                  active={enableChange}
                  onClick={() => {
                    if (!enableChange) {
                      return;
                    }

                    const alertConfig: AlertConfig = {
                      title: '',
                      message: '登録口座が変更されました',
                    };

                    submitBankInfo(
                      employeeID,
                      financialInstitutionCode,
                      branchCode,
                      accountType.toString(),
                      accountNumber,
                      convertAccountName(accountName),
                      alertConfig
                    );
                  }}
                >
                  変更する
                </SubmitButton>

                <CancelButton
                  onClick={() => {
                    setCanselButtonPushed(true);
                  }}
                >
                  {i18n.t('button.back')}
                </CancelButton>
              </div>
            </section>
          </section>
          <SideBar selectedPage={selectedPages.settings} />
        </section>
        <footer>© Metaps Payment Inc.</footer>
      </main>
    </RestrictedPage>
  );
};

const Component = (props: StatesFromReducer & DispatchProps) => {
  if (Object.keys(props.user).length === 0) {
    return <RestrictedPage />;
  }

  return <InnerComponent {...props} />;
};

const mapStateToProps = (state: GlobalState): StatesFromReducer => ({
  ...state.alert,
  ...state.userInfo,
  ...state.franchiseStores,
  ...state.financialInstitutions,
  ...state.financialInstitutionBranches,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadFinancialInstitution: (employeeID, bankName: string) =>
    dispatch(fetchFinancialInstitution(employeeID, bankName)),
  loadFinancialInstitutionBranch: (
    employeeID,
    financialCode: string,
    branchName
  ) =>
    dispatch(
      fetchFinancialInstitutionBranches(employeeID, financialCode, branchName)
    ),
  clearFinancialInstitutionBranches: () =>
    dispatch(removeFinancialInstitutionBranches()),
  submitBankInfo: (
    employeeID,
    financialInstitutionCode,
    branchCode,
    accountType,
    accountNumber,
    accountName,
    meta
  ) =>
    dispatch(
      putUser(
        employeeID,
        financialInstitutionCode,
        branchCode,
        accountType,
        accountNumber,
        accountName,
        meta
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
