import {i18n} from '../../locale/i18n';
import {useState, useEffect} from 'react';
import './Pincode.scss';
import './Modal.scss';
import SubmitButton from '../../components/parts/SubmitButton';
import WhiteOutButton from '../../components/parts/WhiteOutButton';
import Pincodes from '../../components/parts/Pincodes';
import ReactModal from 'react-modal';
import enterPin from '../../actions/enterPin';
import initPin from '../../actions/initPin';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {GlobalState} from '../../reducers/index';
import {PinCodeState} from '../../reducers/pinCode';
import removeAppPass from '../../actions/removeAppPass';
import removeAuthToken from '../../actions/removeAuthToken';
import setPinCodeFragmentAction from '../../actions/setPinCodeFragment';

interface Props {
  pincodeOpened: boolean;
  setPincodeOpened: (pincodeOpened: boolean) => void;
  submit: () => void;
}

const Pincode = ({
  pincodeOpened,
  setPincodeOpened,
  submit,
  veriryPin,
  callInitPin,
  removePin,
  setPinCodeFragment,
  pin,
  completed,
  hasError,
}: Props & DispatchProps & PinCodeState) => {
  const [resetPassword, setResetPassword] = useState(false);
  const [validPasscode, setValidPasscode] = useState(false);
  const [inputPincode, setInputPincode] = useState('');
  const [clearPincode, setClearPincode] = useState(() => () => {});

  useEffect(() => {
    if (completed) {
      if (pincodeOpened) {
        callInitPin();
        setPincodeOpened(false);
        submit();
      }
    }
  }, [callInitPin, completed, pincodeOpened, setPincodeOpened, submit]);

  if (resetPassword) {
    return (
      <ReactModal
        ariaHideApp={false}
        isOpen={true}
        onRequestClose={() => {
          setPincodeOpened(false);
        }}
        contentLabel='Settings'
        overlayClassName='overlay'
        className='home-pincode-reset'
      >
        <section>
          <h2>{i18n.t('appPassword.reset')}</h2>
          <p>{i18n.t('appPassword.resetNextLogin')}</p>

          <WhiteOutButton onClick={removePin}>
            {i18n.t('settings.logout')}
          </WhiteOutButton>

          <div className='back-to-input'>
            <WhiteOutButton
              onClick={() => {
                setResetPassword(false);
              }}
            >
              {i18n.t('appPassword.goBack')}
            </WhiteOutButton>
          </div>
        </section>
      </ReactModal>
    );
  }
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={pincodeOpened}
      onRequestClose={() => {
        setPincodeOpened(false);
      }}
      contentLabel='Settings'
      overlayClassName='overlay'
      className='home-pincode'
    >
      <section>
        {hasError ? (
          <h2 className='error'>{i18n.t('appPassword.error')}</h2>
        ) : (
          <h2>{i18n.t('appPassword.input')}</h2>
        )}
        <h3>{i18n.t('appPassword.inputPasscode')}</h3>
        <Pincodes
          setPincodes={(pincode: string) => {
            setInputPincode(pincode);
          }}
          setValid={setValidPasscode}
          clearPincodes={(clearMethod: () => void) => {
            setClearPincode(() => clearMethod);
          }}
          setPinCodeFragment={setPinCodeFragment}
        />

        <SubmitButton
          onClick={() => {
            veriryPin(inputPincode);
            clearPincode();
          }}
          active={validPasscode}
        >
          {i18n.t('button.confirm')}
        </SubmitButton>
        <p
          className='reset-pincode'
          onClick={() => {
            setResetPassword(true);
          }}
        >
          <span>{i18n.t('appPassword.forgotPasscode')}</span>
          <img alt='right-arrow' src='/img/home/right-allow.svg' />
        </p>

        <WhiteOutButton
          onClick={() => {
            setPincodeOpened(false);
          }}
        >
          {i18n.t('button.cancel')}
        </WhiteOutButton>
      </section>
    </ReactModal>
  );
};

const mapStateToProps = (state: GlobalState): PinCodeState => state.pinCode;

interface DispatchProps {
  veriryPin: typeof enterPin;
  callInitPin: typeof initPin;
  removePin: () => void;
  setPinCodeFragment: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  veriryPin: (pin: string) => dispatch(enterPin(pin)),
  callInitPin: () => dispatch(initPin()),
  removePin: () => {
    dispatch(removeAppPass());
    dispatch(removeAuthToken());
  },
  setPinCodeFragment: () => {
    dispatch(setPinCodeFragmentAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Pincode);
