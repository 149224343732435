import './BankDetail.scss';
import './Detail.scss';
import moment from 'moment';
import commaNumber from 'comma-number';
import {
  BalanceHistory,
  BankDetail as BankDetailType,
} from '../../reducers/balanceHistories';
import maskString from '../../util/maskString';
import {getOemName} from '../../util/oem';

const BankDetail = ({
  balanceHistory,
  close,
}: {
  balanceHistory: BalanceHistory;
  close: () => any;
}) => {
  const {transferAmount, systemFee, transferFee, bankDetail} = balanceHistory;
  const {
    accountType,
    branchName,
    accountNumber,
    financialInstitutionName,
    transferDate,
  } = bankDetail as BankDetailType;
  const table = [
    {
      title: '振込日',
      value: moment(transferDate).format('Y年M月D日'),
    },
    {
      title: '金融機関',
      value: financialInstitutionName,
    },
    {
      title: '支店',
      value: branchName,
    },
    {
      title: '口座番号',
      value: `(${accountType === 1 ? '普' : '当'}) ${maskString(
        accountNumber,
        '*',
        0,
        3
      )}`,
    },
  ];

  const oemName = getOemName();

  return (
    <div className='bank-detail history-detail' onClick={close}>
      <div className='amount-and-fee'>
        <div className='icon' />
        <div className='amount'>
          <p>受取金額</p>
          <p>{`${commaNumber(transferAmount)}円`}</p>
        </div>
        <div className='fee'>
          <p>手数料</p>
          <p>{`${commaNumber(systemFee + transferFee)}円`}</p>
        </div>
        <div className='expand' />
      </div>
      <div className='account'>
        <h3>
          <div className='icon' />
          <div className='contents'>
            <div className='icon-and-name'>
              <img
                alt='passbook'
                src={`/img/accounting-history/icons/${oemName}/passbook.svg`}
              />
              <p>銀行口座振込</p>
            </div>
            <ul>
              {table.map(({title, value}) => (
                <li key={title}>
                  <ul>
                    <li>{title}</li>
                    <li>{value}</li>
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          <div />
          <div className='expand' />
        </h3>
      </div>
    </div>
  );
};

export default BankDetail;
