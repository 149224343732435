const maskString = (
  str: string,
  mask: string,
  start: number | string,
  end: number | string
): string => {
  const startIndex = typeof start === 'string' ? str.indexOf(start) + 1 : start;
  const endIndex = typeof end === 'string' ? str.lastIndexOf(end) : end + 1;
  if (endIndex === -1 || endIndex <= startIndex) {
    return str;
  }
  const range = str.substring(startIndex, endIndex);
  return str.replace(range, mask.repeat(range.length));
};

export default maskString;
