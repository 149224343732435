import {i18n} from '../../locale/i18n';
import './Passcode.scss';
import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import SubmitButton from '../parts/SubmitButton';
import DocumentTitle from '../parts/DocumentTitle';
import confirmPin from '../../actions/confirmPin';
import {useNavigate} from 'react-router-dom';
import Pincodes from '../../components/parts/Pincodes';
import {GlobalState} from '../../reducers/index';
import {AuthTokenState} from '../../reducers/authToken';
import {ConfirmationCodeState} from '../../reducers/confirmationCode';
import {PinCodeState} from '../../reducers/pinCode';
import {FranchiseStoresState} from '../../reducers/franchiseStores';
import fetchApplicationSummary from '../../actions/fetchApplicationSummary';
import RestrictedPage from '../auth/RestrictedPage';

const Passcode = ({
  input,
  showPasscodeDialog,
  showPasscodeDialogFromConfirmationCode,
  completed,
  selected,
  loadApplicationSummary,
}: DispatchProps & StatesFromReducer) => {
  const [validPasscode, setValidPasscode] = useState(false);
  const [inputPincode, setInputPincode] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (
      completed ||
      (!showPasscodeDialog && !showPasscodeDialogFromConfirmationCode)
    ) {
      if (selected) {
        loadApplicationSummary(selected.employeeID);
      }
      navigate('/');
    }
  }, [
    navigate,
    completed,
    showPasscodeDialog,
    showPasscodeDialogFromConfirmationCode,
    selected,
    loadApplicationSummary,
  ]);

  const annotations = i18n.t('appPassword.annotation').split('\n');
  return (
    <RestrictedPage>
      <main id='passcode'>
        <DocumentTitle pageNameInTitle='set app passcode' />
        <div>
          <h2>{i18n.t('appPassword.setUp')}</h2>
          {annotations.map((annotation: string) => (
            <p key={annotation}>{annotation}</p>
          ))}
          <form>
            <Pincodes
              setPincodes={(setPincode: string) => {
                setInputPincode(setPincode);
              }}
              setValid={setValidPasscode}
            />

            <SubmitButton
              active={validPasscode}
              onClick={() => {
                input(inputPincode);
              }}
            >
              {validPasscode
                ? i18n.t('appPassword.set')
                : i18n.t('appPassword.enter')}
            </SubmitButton>
          </form>
        </div>
      </main>
    </RestrictedPage>
  );
};

type StatesFromReducer = AuthTokenState &
  ConfirmationCodeState &
  PinCodeState &
  FranchiseStoresState;
const mapStateToProps = (state: GlobalState): StatesFromReducer => ({
  ...state.franchiseStores,
  ...state.authToken,
  ...state.confirmationCode,
  ...state.pinCode,
});

interface DispatchProps {
  loadApplicationSummary: typeof fetchApplicationSummary;
  input: typeof confirmPin;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  input: (pin: string) => dispatch(confirmPin(pin)),
  loadApplicationSummary: (employeeID: number) =>
    dispatch(fetchApplicationSummary(employeeID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Passcode);
