import {i18n} from '../../locale/i18n';
import './BankDetail.scss';
import {accountTypes} from '../home/constants';
import maskString from '../../util/maskString';

const BankDetail = ({
  bankAccount,
}: {
  bankAccount: {
    financialInstitutionName: string;
    branchName: string;
    accountType: number;
    accountNumber: string;
  };
}) => {
  const {financialInstitutionName, branchName, accountType, accountNumber} =
    bankAccount;
  return (
    <ul className='bank-detail'>
      <li>
        <ul>
          <li>{i18n.t('common.bank')}</li>
          <li>{financialInstitutionName}</li>
        </ul>
      </li>
      <li>
        <ul>
          <li>{i18n.t('common.branch')}</li>
          <li>{branchName}</li>
        </ul>
      </li>
      <li>
        <ul>
          <li>{i18n.t('common.accountType')}</li>
          <li>{accountTypes[accountType]}</li>
        </ul>
      </li>
      <li>
        <ul>
          <li>{i18n.t('common.accountNumber')}</li>
          <li>{maskString(accountNumber, '*', 0, 3)}</li>
        </ul>
      </li>
    </ul>
  );
};

export default BankDetail;
